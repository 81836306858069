<script setup lang="ts">
  import type { ButtonBaseProps } from '~/types/design-system'
  import type { ButtonBranding } from '~/types/branding'

  withDefaults(
    defineProps<{
      buttonBranding: ButtonBranding
      type?: ButtonBaseProps['type']
      loading?: boolean
      disabled?: boolean
    }>(),
    {
      type: 'button',
      loading: false,
      disabled: false
    }
  )

  const emit = defineEmits<{
    click: [MouseEvent]
  }>()
</script>

<template>
  <ButtonBase
    :radius="buttonBranding.radius"
    :background-color="buttonBranding.backgroundColor"
    :hover-background-color="buttonBranding.hoverBackgroundColor"
    :font-size="buttonBranding.fontSize"
    :text-color="buttonBranding.textColor"
    :hover-text-color="buttonBranding.hoverTextColor"
    :weight="buttonBranding.weight"
    :type="type"
    :loading="loading"
    :disabled="disabled"
    @click.stop="emit('click', $event)"
  >
    <slot>
      {{ buttonBranding.text }}
    </slot>
  </ButtonBase>
</template>
